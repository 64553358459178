<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="地址" prop="address">
        <a-input v-model="form.address" placeholder="请输入地址" />
      </a-form-model-item>
      <a-form-model-item label="已绑定智能秤" prop="deviceId" v-if="form.scaleVos && form.scaleVos.length">
        <div v-for="item in form.scaleVos" :key="item.id">
          <a-span>{{ item.deviceId }}</a-span>
        </div>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="saveLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPostStationInfo, addPostStationInfo, updatePostStationInfo } from '@/api/recyclable/postStationInfo'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        name: null,
        address: null,
        userId: null,
        tenantCode: null,
        status: '0'
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {},
      saveLoading: false
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        name: null,
        address: null,
        userId: 100,
        tenantCode: this.getTenantCode(),
        status: '0'
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPostStationInfo(id).then(response => {
        this.form = response.data
        this.form.scaleVos = row.scaleVos
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePostStationInfo(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          } else {
            addPostStationInfo(this.form)
              .then(response => {
                this.saveLoading = false
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .catch(() => {
                this.saveLoading = false
              })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
